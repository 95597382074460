import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Banner from "../components/banner"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFire } from "@fortawesome/free-solid-svg-icons"

const Terms = () => (
  <>
    <Layout>
      <Seo title="Terms and Conditions" />
      <Banner title="Terms and Conditions" />
      <section id="services" className="single-features pt-5 pb-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <p className="pt-2 pb-2 lead">
                This website and its content are owned by Lappy nation. It would
                not be printed/ copied/ used without any prior permission from
                Lappy Nation.
              </p>
              <p className="pt-2 pb-2 lead">
                <FontAwesomeIcon icon={faFire} /> Lappy Nation explicitly saves
                all rights related to content and accumulation of all the
                content on the site
              </p>
              <p className="pt-2 pb-2 lead">
                <FontAwesomeIcon icon={faFire} /> One should be agreeing to our
                terms and conditions provided below before renting from us.
              </p>
              <p className="pt-2 pb-2 lead">
                <FontAwesomeIcon icon={faFire} /> Payment need to be made in
                advance as per terms in contract.
              </p>
              <p className="pt-2 pb-2 lead">
                <FontAwesomeIcon icon={faFire} /> Payment can be done via
                Cheque/NEFT/ RTGS/ IMPS/ UPI/ Phonepe/ Paytm.
              </p>
              <p className="pt-2 pb-2 lead">
                <FontAwesomeIcon icon={faFire} /> One month security amount need
                to be submitted that will be refunded at the time of closure.
              </p>
              <p className="pt-2 pb-2 lead">
                <FontAwesomeIcon icon={faFire} /> Lappy nation will not offer
                any kind of software/ antivirus.
              </p>
              <p className="pt-2 pb-2 lead">
                <FontAwesomeIcon icon={faFire} /> In case of any damage caused
                by any means to the product will result in extra cost that needs
                to be paid accordingly to the amount used in the repair/
                restructure of the product.
              </p>
              <p className="pt-2 pb-2 lead">
                <FontAwesomeIcon icon={faFire} /> Adulteration with the product
                or its part will result in law of action.
              </p>
              <p className="pt-2 pb-2 lead">
                <FontAwesomeIcon icon={faFire} /> Installing any pirated
                software is strictly forbidden.
              </p>
              <p className="pt-2 pb-2 lead">
                <FontAwesomeIcon icon={faFire} /> Renting period may be of
                1Month/ 3Months/ 6 months/ 9 months/ a Year and more. Renewing
                the same will be intimated to us one week before the completion
                of the period.
              </p>
              <p className="pt-2 pb-2 lead">
                <FontAwesomeIcon icon={faFire} /> Minimum renting period is of 1
                month
              </p>
              <p className="pt-2 pb-2 lead">
                <FontAwesomeIcon icon={faFire} /> Your own data filled in the
                enlistment structure or the installment subtleties entered by
                you are safely secured in our data base.
              </p>
              <p className="pt-2 pb-2 lead">
                <FontAwesomeIcon icon={faFire} /> We completely ensure that we
                will not disclose your details to any third party without
                seeking your prior permissions.
              </p>
              <p className="pt-2 pb-2 lead">
                <FontAwesomeIcon icon={faFire} /> We assure you 24*7 assistance
                for any of your concerns
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  </>
)

export default Terms
